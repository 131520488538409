
import Vue from "vue";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      text: `Seja bem-vindo ao Pro Market, o sistema que vai auxiliar na sua comercialização agrícola.
           Tornando simples e objetiva a sua tomada de decisão.`,
      services: [
        {
          id: 1,
          text: "Rentabilidade em tempo real",
          image: "profit.png",
        },
        {
          id: 2,
          text: "Gatilhos de Venda",
          image: "money.png",
        },
        {
          id: 3,
          text: "Controle de Posições",
          image: "shopping.png",
        },
        {
          id: 4,
          text: "Call de Mercado",
          image: "contact.png",
        },
        {
          id: 5,
          text: "Basis",
          image: "browser.png",
        },

        {
          id: 6,
          text: "Relatórios Gerenciais",
          image: "dashboard.png",
        },
      ],
    };
  },
  methods: {
    getImgUrl(image: string) {
      // var images = require.context('../assets/', false, /\.png$/)
      // return images('./' + pet + ".png")
      return require(`@/assets/home/${image}`);
    },
  },
});
